<script>
const propName = "value", eventName = "input"
import {mdiChevronRight, mdiChevronLeft} from '@mdi/js';
export default {
  name: "InputText",
  model: {
    prop: propName,
    event: eventName
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    regex: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    [propName]: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
      required: true
    },
    fieldId: {
      type: Number,
      required: true,
    },
    savedValue: {
      required: true,
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
    },
    required: {
      type: Boolean,
    },
    multipleForms: {
      type: Boolean,
    },
    multipleFormsIndex: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
    },
  },
  data: function (){
    return {
      mdiChevronRight,
      mdiChevronLeft,
      requiredRule: (v) => !!v || this.$t("pages.questionnaire.rules.required"),
      regexRule: (v) => RegExp(this.regex).test(v) || this.$t("pages.questionnaire.rules.format")
    }
  },
  computed: {
    rules() {
      let rulesArray = []
      if (this.required) rulesArray.push(this.requiredRule)
      if (this.regex) rulesArray.push(this.regexRule)
      return rulesArray
    },
    model: {
      get() {
        return this[propName]
      },
      set(payload) {
        this.$emit(eventName, {
          fieldId: this.fieldId,
          value: payload
        })
      }
    },
  },
  created() {
    let value
    if (!!this.savedValue && this.savedValue !== "None")  {
      value = this.savedValue
    } else if (this.defaultValue) {
      value = this.defaultValue
    }
    if (value)
      this.model = this.multipleForms ? value[this.multipleFormsIndex] : value
  },
  methods: {
    onEnter() {
      this.$emit("enter");
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <v-carousel
      v-if="$attrs.statistics_data"
      :continuous="false"
      show-arrows
      hide-delimiters
      height="300"
      class="carousel"
    >
      <template #prev="{on, attrs}">
        <v-icon
          v-bind="attrs"
          v-on="on"
        >
          {{ mdiChevronLeft }}
        </v-icon>
      </template>
      <template #next="{on, attrs}">
        <v-icon
          v-bind="attrs"
          v-on="on"
        >
          {{ mdiChevronRight }}
        </v-icon>
      </template>
      <v-carousel-item
        v-if="$attrs.statistics_data.length === 0"
      >
        <div class="text-body-2 d-flex justify-center flex-column fill-height px-14">
          <p>Нет комментариев</p>
        </div>
      </v-carousel-item>
      <v-carousel-item
        v-for="(comment, i) in $attrs.statistics_data"
        :key="i"
      >
        <div class="text-body-2 d-flex justify-center flex-column fill-height px-14">
          <p>Комментарий <strong>{{ i + 1 }}</strong> из <strong>{{ $attrs.statistics_data.length }}</strong></p>
          <p class="font-italic">
            {{ comment }}
          </p>
        </div>
      </v-carousel-item>
    </v-carousel>
    <v-text-field
      v-else
      v-model="model"
      :hint="hint"
      :placeholder="placeholder"
      :label="label"
      :rules="rules"
      :pattern="regex"
      outlined
      block
      :disabled="disabled"
      x-large
      class="field"
      validate-on-blur
      @keydown.enter="onEnter"
    />
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 100%;
}
.field::v-deep {
  border-radius: 10px;
  .v-btn__content {
    color: black;
  }
}
.carousel::v-deep {
  .v-window__next, .v-window__prev {
    margin: 0;
  }
}
</style>